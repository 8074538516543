import {Component, Input} from '@angular/core';
import {ModalService} from '../../../services/modal.service';
import {FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'tna-confirm-modal',
  templateUrl: './cancel-report-modal.component.html',
})
export class CancelReportModalComponent {
  @Input()
  public message = 'Wat is de reden van het annuleren?';

  @Input()
  public confirmText = 'Melding annuleren';

  @Input()
  public cancelText = 'Sluiten';

  public reason: FormControl = new FormControl('', [Validators.required]);

  constructor(private modalService: ModalService) {
  }

  public onConfirm(): void {
    this.modalService.close({success: true, reason: this.reason.value});
  }

  public onCancel(): void {
    this.modalService.close({success: false});
  }
}
