import {
  State,
  Action,
  StateContext,
  Selector,
  Store,
  NgxsOnInit,
} from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';

import { ProductsStateModel } from './products.state-model';
import { ProductsService } from '../../services/products.service';

import {
  LoadProducts,
  OpenProductsList,
  OpenProductForm,
  DeleteProduct,
  SaveProduct,
} from './products.actions';
import { EnvironmentState } from '../environment';
import { Product } from '../../models';
import { Observable } from 'rxjs';
import { tap, switchMap, filter } from 'rxjs/operators';
import { AppEventsService } from '../../services/app-events.service';
import {
  patch,
  removeItem,
  iif,
  updateItem,
  insertItem,
} from '@ngxs/store/operators';
import { AuthState } from '../auth';
import { plainToClass } from 'class-transformer';

@State<ProductsStateModel>({
  name: 'products',
  defaults: {
    products: [],
  },
})
@Injectable()
export class ProductsState implements NgxsOnInit {
  @Selector()
  static products(state: ProductsStateModel): Array<Product> {
    return state.products;
  }

  public constructor(
    private store: Store,
    private appEventsService: AppEventsService,
    private productsService: ProductsService
  ) {}

  public ngxsOnInit(ctx: StateContext<ProductsStateModel>): void {
    this.store
      .select(AuthState.token)
      .pipe(
        filter((token: string) => !!token),
        switchMap(() => this.store.select(AuthState.isAdmin)),
        filter((isAdmin) => isAdmin),
        switchMap(() => ctx.dispatch(new LoadProducts()))
      )
      .subscribe();
  }

  @Action(OpenProductsList)
  public openProducts(): Observable<any> {
    const prefix = this.store.selectSnapshot(EnvironmentState.prefix);
    return this.store.dispatch(new Navigate([`/${prefix}/products`]));
  }

  @Action(OpenProductForm)
  public openProductForm(
    _: StateContext<ProductsStateModel>,
    { product }: OpenProductForm
  ): Observable<any> {
    const prefix = this.store.selectSnapshot(EnvironmentState.prefix);

    if (product) {
      return this.store.dispatch(
        new Navigate([`/${prefix}/products/${product.id}`])
      );
    }

    return this.store.dispatch(new Navigate([`/${prefix}/products/add`]));
  }

  @Action(LoadProducts)
  public loadProducts(
    ctx: StateContext<ProductsStateModel>
  ): Observable<Array<Product>> {
    return this.productsService
      .getProducts()
      .pipe(tap((products) => ctx.patchState({ products })));
  }

  @Action(DeleteProduct)
  public deleteProduct(
    ctx: StateContext<ProductsStateModel>,
    { product }: DeleteProduct
  ): Observable<any> {
    return this.appEventsService
      .save({
        event: 'product.deleted',
        payload: product,
      })
      .pipe(
        tap((appEvent: any) =>
          ctx.setState(
            patch({
              products: removeItem<Product>(
                (p) => p.id === appEvent.payload.id
              ),
            })
          )
        )
      );
  }

  @Action(SaveProduct)
  public saveProduct(
    ctx: StateContext<ProductsStateModel>,
    { product }: SaveProduct
  ): Observable<Product> {
    return this.appEventsService
      .save({
        event: 'product.saved',
        payload: product,
      })
      .pipe(
        tap((appEvent: any) => {
          ctx.setState(
            patch({
              products: iif<Product[]>(
                (users) => users.some((p) => p.id === appEvent.payload.id),
                updateItem<Product>(
                  (p) => p.id === appEvent.payload.id,
                  plainToClass(Product, appEvent.payload)
                ),
                insertItem<Product>(plainToClass(Product, appEvent.payload))
              ),
            })
          );
        })
      );
  }
}
