import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Observable } from 'rxjs';

import { OpenStockList, OpenStockItemForm } from './stock.actions';
import { StockItem } from '../../models';
import { EnvironmentState } from '../environment';
import { StockStateModel } from './stock.state-model';

@State<StockStateModel>({
  name: 'stock',
  defaults: {
    stockItems: [],
    stockItem: null,
  },
})
@Injectable()
export class StockState {
  public constructor(private store: Store) {}

  @Selector()
  static stocks(state: StockStateModel): Array<StockItem> {
    return state.stockItems;
  }

  @Selector()
  static stock(state: StockStateModel): StockItem {
    return state.stockItem;
  }

  @Action(OpenStockList)
  public openReporstList(): Observable<any> {
    const prefix = this.store.selectSnapshot(EnvironmentState.prefix);
    return this.store.dispatch(new Navigate([`/${prefix}/stock`]));
  }

  @Action(OpenStockItemForm)
  public openStockItemForm(
    _: StateContext<StockStateModel>,
    { stockItem }: OpenStockItemForm
  ): Observable<any> {
    const prefix = this.store.selectSnapshot(EnvironmentState.prefix);

    if (!['admin', 'financial', 'planner'].includes(prefix)) {
      return;
    }

    return this.store.dispatch(
      new Navigate([`/${prefix}/stock/${stockItem.mechanic.id}`])
    );
  }
}
