import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AbstractControl,
  FormGroup,
  FormControl,
  Validators,
  ValidationErrors,
} from '@angular/forms';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { UsersService } from '@vandelft/modules/shared/services/users.service';
import { User } from '@vandelft/modules/shared/models';
import { SaveUser, OpenUsersList } from '@vandelft/modules/shared/state/users';

@Component({
  selector: 'tna-users-form',
  templateUrl: './form.component.html',
})
export class FormComponent implements OnInit {
  public isBusy = false;
  public userId: string;
  public roles = [
    {
      value: 'admin',
      label: 'Beheerder',
    },
    {
      value: 'financial',
      label: 'Administratie',
    },
    {
      value: 'mechanic',
      label: 'Monteur',
    },
    {
      value: 'planner',
      label: 'Planner',
    },
  ];

  public userForm = new FormGroup({
    firstName: new FormControl(null, [Validators.required]),
    middleName: new FormControl(null),
    lastName: new FormControl(null, [Validators.required]),
    username: new FormControl(
      null,
      [Validators.required, Validators.email],
      [(c) => this.validateEmailNotTaken(c)]
    ),
    role: new FormControl(null, [Validators.required]),
  });

  public constructor(
    private route: ActivatedRoute,
    private store: Store,
    private usersService: UsersService
  ) {}

  public async ngOnInit(): Promise<void> {
    const userId = this.route.snapshot.paramMap.get('id');
    this.userId = userId || uuid();

    if (userId) {
      const user = await this.usersService
        .getUserById(userId)
        .pipe(first())
        .toPromise();
      this.userForm.patchValue(user);
    }
  }

  public onSubmit(): void {
    try {
      this.isBusy = true;
      const user = User.plainToClass(User, {
        ...this.userForm.value,
        id: this.userId,
      });
      this.store.dispatch([new SaveUser(user), new OpenUsersList()]);
    } catch (e) {
    } finally {
      this.isBusy = false;
    }
  }

  public validateEmailNotTaken(
    control: AbstractControl
  ): Observable<ValidationErrors> {
    return this.usersService
      .checkEmailInUse(control.value, this.userId)
      .pipe(map((taken) => (taken ? { emailTaken: true } : null)));
  }
}
