import { Expose, Exclude } from 'class-transformer';
import { Observable } from 'rxjs';

import { BaseModel } from './base-model';

@Exclude()
export class LocalImage extends BaseModel {
  @Expose()
  public id: string;

  @Expose()
  public name: string;

  @Expose()
  public size: string;

  @Expose()
  public thumbnailData: Observable<string>;

  @Expose()
  public data: string;
}
