<div class="message">{{message}}</div>
<textarea [formControl]="reason" id="message" cols="30" rows="10"></textarea>
<div class="actions">
  <button
    class="btn btn-primary btn-sm"
    [disabled]="reason.invalid"
    (click)="onConfirm()">
    {{confirmText}}
  </button>
  <button
    class="btn btn-primary btn-outline btn-sm ml-1"
    (click)="onCancel()">
    {{cancelText}}
  </button>
</div>
