import { Type, Expose, Exclude } from 'class-transformer';

import { BaseModel } from './base-model';
import { Address } from './address';
import { IClient } from '@vandelft/shared/interfaces';

@Exclude()
export class Client extends BaseModel implements IClient {
  @Expose()
  public id: string;

  @Expose()
  public clientAddressId: string;

  @Expose()
  public locationAddressId: string;

  @Expose()
  public type: string;

  @Expose()
  @Type(() => Address)
  public clientAddress: Address;

  @Expose()
  @Type(() => Address)
  public locationAddress: Address;

  public get effectiveLocationAddress(): Address {
    if (this.locationAddress) {
      return this.locationAddress;
    }

    return this.clientAddress;
  }
}
