import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { patch, removeItem } from '@ngxs/store/operators';
import { Observable } from 'rxjs';

import {
  OpenDepositItemsList,
  RegisterDeposit,
  LoadDepositItems,
  RefreshUserDepositItem,
  LoadMechanicDepositItem,
} from './deposit.actions';
import { DepositItem } from '../../models';
import { EnvironmentState } from '../environment';
import { DepositStateModel } from './deposit.state-model';
import { DepositItemsService } from '../../services/deposit-items.service';
import { AuthState } from '../auth';
import { AppEventsService } from '../../services/app-events.service';

@State<DepositStateModel>({
  name: 'Deposit',
  defaults: {
    depositItems: [],
    mechanicDepositItem: null,
  },
})
@Injectable()
export class DepositState {
  public constructor(
    private store: Store,
    private appEventsService: AppEventsService,
    private depositItemsService: DepositItemsService
  ) {}

  @Selector()
  static depositItems(state: DepositStateModel): Array<DepositItem> {
    return state.depositItems;
  }

  @Selector()
  static mechanicDepositItem(state: DepositStateModel): DepositItem {
    return state.mechanicDepositItem;
  }

  @Action(LoadDepositItems)
  public async loadDepositItems(
    ctx: StateContext<DepositStateModel>
  ): Promise<void> {
    const depositItems = await this.depositItemsService.getAll().toPromise();

    ctx.patchState({
      depositItems,
    });
  }

  @Action(RefreshUserDepositItem)
  public async refreshMechanicDepositItem(
    ctx: StateContext<DepositStateModel>
  ): Promise<void> {
    const user = this.store.selectSnapshot(AuthState.user);

    if (user.role !== 'mechanic') {
      return;
    }

    const mechanicDepositItem = await this.depositItemsService
      .getByMechanicId(user.id)
      .toPromise();

    ctx.patchState({
      mechanicDepositItem,
    });
  }

  @Action(LoadMechanicDepositItem)
  public async LoadMechanicDepositItem(
    ctx: StateContext<DepositStateModel>,
    { mechanicId }: LoadMechanicDepositItem
  ): Promise<void> {
    const mechanicDepositItem = await this.depositItemsService
      .getByMechanicId(mechanicId)
      .toPromise();

    ctx.patchState({
      mechanicDepositItem,
    });
  }

  @Action(OpenDepositItemsList)
  public openDepositItemsList(): Observable<any> {
    const prefix = this.store.selectSnapshot(EnvironmentState.prefix);
    return this.store.dispatch(new Navigate([`/${prefix}/deposit`]));
  }

  @Action(RegisterDeposit)
  public async registerDeposit(
    ctx: StateContext<DepositStateModel>,
    { depositItem }: RegisterDeposit
  ): Promise<void> {
    const prefix = this.store.selectSnapshot(EnvironmentState.prefix);

    if (!['admin', 'planner', 'financial'].includes(prefix)) {
      return;
    }

    const event = {
      event: 'mechanic.deposited',
      payload: { mechanicId: depositItem.mechanic.id },
    };

    await this.appEventsService.save(event).toPromise();

    ctx.setState(
      patch({
        depositItems: removeItem<DepositItem>(
          (d) => d.mechanic.id === depositItem.mechanic.id
        ),
      })
    );

    await this.store.dispatch(new Navigate([`/${prefix}/deposit`]));
  }
}
