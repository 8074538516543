import * as moment from 'moment/moment';

export const datetimeToPlain = (value: any): string => {
  if (!value) {

    return value;
  }

  return moment(value).format('YYYY-MM-DD HH:mm:ss');
};
