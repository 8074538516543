import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { DepositItem } from '../models';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DepositItemsService {
  public constructor(private http: HttpClient) {}

  public getAll(): Observable<DepositItem[]> {
    return this.http
      .get(`${environment.apiUrl}/deposit`)
      .pipe(
        map((data: DepositItem[]) =>
          DepositItem.plainToClass(DepositItem, data)
        )
      );
  }

  public getByMechanicId(mechanicId: string): Observable<DepositItem> {
    return this.http
      .get(`${environment.apiUrl}/deposit/mechanic/${mechanicId}`)
      .pipe(
        map((data: DepositItem) => DepositItem.plainToClass(DepositItem, data))
      );
  }

  public registerDeposit(depositItem: DepositItem): Observable<DepositItem[]> {
    return this.http
      .post(
        `${environment.apiUrl}/deposit/mechanic/${depositItem.mechanic.id}`,
        { depositItem }
      )
      .pipe(
        map((data: DepositItem[]) =>
          DepositItem.plainToClass(DepositItem, data)
        )
      );
  }
}
