<form [formGroup]="form">
  <div class="row">
    <div class="col">
      <div class="form-group required">
        <label class="label">Onderweg</label>
        <ngx-timepicker-field
          [format]="24"
          class="form-control"
          formControlName="onTheGo"
          defaultTime="00:00"
          [controlOnly]="true">
        </ngx-timepicker-field>
      </div>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col">
      <div class="form-group required">
        <label class="label">Aankomst</label>
        <ngx-timepicker-field
          [format]="24"
          class="form-control"
          formControlName="arrival"
          defaultTime="00:00"
          [controlOnly]="true">
        </ngx-timepicker-field>
      </div>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col">
      <div class="form-group required">
        <label class="label">Vertrek</label>
        <ngx-timepicker-field
          [format]="24"
          class="form-control"
          formControlName="departure"
          defaultTime="00:00"
          [controlOnly]="true">
        </ngx-timepicker-field>
      </div>
    </div>
  </div>

  <div class="actions">
    <button
      class="btn btn-primary btn-sm"
      [disabled]="form.invalid"
      (click)="onConfirm()">
      Opslaan
    </button>
    <button
      class="btn btn-primary btn-outline btn-sm ml-1"
      (click)="onCancel()">
      Annuleren
    </button>
  </div>
</form>
