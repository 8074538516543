import { State, StateContext, Store, NgxsOnInit } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { filter, map } from 'rxjs/operators';

import { AuthState } from '../auth';

import { SocketStateModel } from './socket.state-model';
import { SocketService } from '../../services/socket.service';

@State<SocketStateModel>({
  name: 'socket',
  defaults: {},
})
@Injectable()
export class SocketState implements NgxsOnInit {
  public constructor(
    private store: Store,
    private socketService: SocketService
  ) {}

  public ngxsOnInit(ctx: StateContext<SocketStateModel>): void {
    this.store
      .select(AuthState.token)
      .pipe(
        filter((token: string) => !!token),
        map((token) => this.socketService.setToken(token))
      )
      .subscribe();
  }
}
