import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { ResetPassword } from '@vandelft/modules/shared/state/users';
import { Navigate } from '@ngxs/router-plugin';

@Component({
  selector: 'tna-users-forgot-password',
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent {
  public isBusy = false;
  public forgotPasswordForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  public constructor(private store: Store) {}

  public onSubmit(): void {
    try {
      this.isBusy = true;
      const data = this.forgotPasswordForm.value;
      this.store.dispatch([
        new ResetPassword(data.email),
        new Navigate(['/login']),
      ]);
    } catch (e) {
      console.error(e);
    } finally {
      this.isBusy = false;
    }
  }
}
