import { Type, Exclude, Expose, Transform } from 'class-transformer';

import { User } from './user';
import { RefillableProduct } from './refillable-product';
import { BaseModel } from './base-model';

@Exclude()
export class StockItem extends BaseModel {
  @Expose()
  @Type(() => User)
  public mechanic: any;

  @Expose()
  @Transform(value => value || [])
  @Type(() => RefillableProduct)
  public products: RefillableProduct[];


  public get totalProductQuantity(): number {
    return this.products.reduce((prev: number, cur: any) => {
      if (cur.articleNumber) {
        return prev + cur.quantity;
      }

      return prev;
    }, 0);
  }
}
