<div class="card mb-1">
  <a class="btn btn-primary" #download>Download</a>
</div>

<div class="card mb-1">
  <h1 (click)="workOrdersOpen = !workOrdersOpen">
    Werkbonnen <small>({{ workOrders?.length }})</small>
  </h1>

  <div *ngIf="workOrdersOpen">
    <div *ngFor="let item of workOrders; let index = index">
      <pre>{{ item | json }}</pre>
      <hr />
    </div>
  </div>
</div>

<div class="card">
  <h1 (click)="(workOrderDraftsOpen)">
    Drafts <small>({{ workOrderDrafts?.length }})</small>
  </h1>

  <div *ngIf="workOrderDraftsOpen">
    <div *ngFor="let item of workOrderDrafts; let index = index">
      <pre>{{ item | json }}</pre>
      <hr />
    </div>
  </div>
</div>
