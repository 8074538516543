import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { UsersService } from '@vandelft/modules/shared/services/users.service';
import {Store} from '@ngxs/store';
import {LogoutUser} from '@vandelft/modules/shared/state/auth';

@Component({
  selector: 'tna-users-activation',
  templateUrl: './activation.component.html',
})
export class ActivationComponent implements OnInit {
  @Output()
  public activate = new EventEmitter();

  @Output()
  public activationError = new EventEmitter();

  public isBusy = false;
  public activationForm = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    repeatPassword: new FormControl('', [Validators.required, Validators.minLength(6), this.passwordsMatch()]),
  });

  public constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private usersService: UsersService
  ) { }


  public async ngOnInit(): Promise<void> {
    try {
      const hash = this.route.snapshot.paramMap.get('hash');
      await this.usersService.checkHash(hash).toPromise();
    } catch (e) {
      this.store.dispatch(new LogoutUser());
    }
  }

  public passwordsMatch(): any {
    return (control: FormControl): { [s: string]: boolean } => {
      if (!this.activationForm) {
        return null;
      }

      const passwordValue = this.activationForm.value.password;
      const repeatPasswordValue = control.value;

      if (passwordValue !== repeatPasswordValue) {
        return { notEqual: true };
      }

      return null;
    };
  }


  public async onSubmit(): Promise<void> {
    try {
      this.isBusy = true;
      const hash = this.route.snapshot.paramMap.get('hash');
      const password = this.activationForm.value.password;
      const result = await this.usersService.activateUser(hash, password).toPromise();
      this.activate.emit(result);
      this.router.navigate(['/login']);
    } catch (e) {
      this.activationError.emit(e);
    } finally {
      this.isBusy = false;
    }
  }

}
