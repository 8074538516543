<form class="form" [formGroup]="userForm" (ngSubmit)="onSubmit()">
  <div class="app-page-header">
    <div class="page-title">
      <div class="container">
        <h1>Gebruiker toevoegen</h1>
        <div class="actions ml-auto">

          <button type="submit" class="btn btn-primary ml-auto" [disabled]="userForm.invalid">
            <span *ngIf="!isBusy" class="text">opslaan</span>
            <span *ngIf="isBusy" class="loader"><img src="assets/img/loader-primary.svg" /></span>
          </button>
        </div>
      </div>
    </div>
  </div>


  <div class="container">
    <div class="card">
      <div class="body p-2">
        <div class="form-group required">
          <label class="label">Naam</label>
          <div class="input-group">
            <input type="text" class="form-control" formControlName="firstName" placeholder="Voornaam" />
            <input type="text" class="form-control middleName" formControlName="middleName" placeholder="Tussenvoegsel" />
            <input type="text" class="form-control" formControlName="lastName" placeholder="Achternaam" />
          </div>
        </div>

        <div class="form-group required">
          <label class="label">E-mailadres</label>
          <input type="text" class="form-control" formControlName="username" autocorrect="off" autocapitalize="none" placeholder="E-mailadres" />
        </div>

        <div class="form-group required">
          <label class="label">Rol</label>
          <select class="form-control" formControlName="role">
            <option *ngFor="let role of roles" [ngValue]="role.value">{{role.label}}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</form>
