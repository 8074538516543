import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { LocalStoreService } from '@vandelft/modules/shared/services/local-store.service';
import { IWorkOrder } from '@vandelft/shared/interfaces';

@Component({
  selector: 'app-debug-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements AfterViewInit {
  @ViewChild('download')
  public download: ElementRef;

  public workOrders: IWorkOrder[];
  public workOrderDrafts: IWorkOrder[];

  public workOrdersOpen = false;
  public workOrderDraftsOpen = false;

  public constructor(private localStorageService: LocalStoreService) {}

  public async ngAfterViewInit() {
    this.workOrders = await this.localStorageService.getWorkOrders();
    this.workOrderDrafts = await this.localStorageService.getWorkOrderDrafts();

    const data = JSON.stringify({
      workOrders: this.workOrders,
      workOrderDrafts: this.workOrderDrafts,
    });

    this.download.nativeElement.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(data)
    );
    this.download.nativeElement.setAttribute('download', 'debug.json');
  }
}
