import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { LogoutUser } from '@vandelft/modules/shared/state/auth';
import { environment } from '@vandelft/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  public version = environment.version;

  public constructor(private store: Store) {}

  public logout(): void {
    this.store.dispatch([new LogoutUser()]);
  }
}
