import { Expose, Exclude } from 'class-transformer';

import { BaseModel } from './base-model';
import { IProduct } from '@vandelft/shared/interfaces';

@Exclude()
export class Product extends BaseModel implements IProduct {
  @Expose()
  public id: string;

  @Expose()
  public articleNumber: string;

  @Expose()
  public description: string;

  @Expose()
  public price: number;

  @Expose()
  public onLoan: boolean;

  public get tax(): number {
    return (this.price / 100) * 21;
  }

  public get priceExc(): number {
    return this.price - this.tax;
  }
}
