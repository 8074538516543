import { BaseModel } from './base-model';
import { Exclude, Expose, Transform, Type } from 'class-transformer';

import { OrderLine } from './order-line';
import { LocalImage } from './local-image';
import { WorkOrder } from './work-order';
import { IImage, IOrder } from '@vandelft/shared/interfaces';
import {
  DepositHelper,
  OptionsHelper,
  OrderHelper,
  QuantifiedProductHelper,
} from '@vandelft/shared/helpers';
import { Image } from './image';

@Exclude()
export class Order extends BaseModel implements IOrder {
  @Expose()
  public id: string;

  @Expose()
  public workOrderId: string;

  @Expose()
  public takenProduct: string;

  @Expose()
  public doorMaterial: string;

  @Expose()
  public frameMaterial: string;

  @Expose()
  public doorType: string;

  @Expose()
  @Transform((value) => (value ? 1 : 0), { toPlainOnly: true })
  public cylinderOperated: boolean;

  @Expose()
  public backsetLock: number;

  @Expose()
  public spacingLock: number;

  @Expose()
  @Transform((value) => (value ? 1 : 0), { toPlainOnly: true })
  public keyRepeatOrder: boolean;

  @Expose()
  @Transform((value) => (value ? 1 : 0), { toPlainOnly: true })
  public cylinder: boolean;

  @Expose()
  public cylinderBrand: string;

  @Expose()
  public cylinderNumber: string;

  @Expose()
  public remarks: string;

  @Expose()
  @Type(() => LocalImage)
  public localCertificates: LocalImage[];

  @Expose()
  @Type(() => LocalImage)
  public localProductImages: LocalImage[];

  @Expose()
  @Type(() => OrderLine)
  public orderLines: OrderLine[];

  @Expose()
  @Type(() => Image)
  public images: Image[];

  @Expose()
  public deposit: number;

  @Expose()
  @Type(() => Image)
  public certificate: Image;

  @Expose()
  @Type(() => Image)
  public productImage: Image;

  @Expose()
  @Type(() => WorkOrder)
  public workOrder: WorkOrder;

  public get productImages(): IImage[] | undefined {
    return OrderHelper.getProductImages(this);
  }

  public get certificates(): IImage[] | undefined {
    return OrderHelper.getCertificates(this);
  }

  public get calculatedDeposit(): number {
    return DepositHelper.getDeposit(this.orderLines);
  }

  public get effectiveDeposit(): number {
    if (!this.deposit) {
      return this.calculatedDeposit;
    }

    return this.deposit;
  }

  public get totalPriceExc(): number {
    return QuantifiedProductHelper.getTotalPriceExc(this.orderLines);
  }

  public get totalTax(): number {
    return QuantifiedProductHelper.getTotalTax(this.orderLines);
  }

  public get totalPriceInc(): number {
    return QuantifiedProductHelper.getTotalPriceInc(this.orderLines);
  }

  public get options(): any {
    return OptionsHelper.getSelectedOptions(this);
  }
}
