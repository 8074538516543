import {
  State,
  Action,
  StateContext,
  Selector,
  Store,
  NgxsOnInit,
} from '@ngxs/store';
import { Injectable } from '@angular/core';

import {
  LoadReportBackItems,
  OpenReportBackItemsList,
  OpenReportBackItemDetails,
  LoadReportBackItemDetails,
  ReportBack,
  CancelReportBack,
} from './report-back-items.actions';

import { ReportBackItemsStateModel } from './report-back-items.state-model';
import { ReportBackItemsService } from '../../services/report-back-items.service';
import { WorkOrder } from '../../models';
import { tap, switchMap, filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { EnvironmentState } from '../environment';
import { Navigate } from '@ngxs/router-plugin';
import { AppEventsService } from '../../services/app-events.service';
import { removeItem, patch } from '@ngxs/store/operators';
import {AuthState} from '../auth';

@State<ReportBackItemsStateModel>({
  name: 'reportBackItems',
  defaults: {
    reportBackItems: [],
    reportBackItem: null,
  },
})
@Injectable()
export class ReportBackItemsState implements NgxsOnInit {
  @Selector()
  static ReportBackItems(state: ReportBackItemsStateModel): Array<WorkOrder> {
    return state.reportBackItems;
  }

  @Selector()
  static ReportBackItem(state: ReportBackItemsStateModel): WorkOrder {
    return state.reportBackItem;
  }

  public constructor(
    private store: Store,
    private reportBackItemsService: ReportBackItemsService,
    private appEventsService: AppEventsService
  ) {}

  public ngxsOnInit(ctx: StateContext<ReportBackItemsStateModel>): void {
    this.store
      .select(AuthState.token)
      .pipe(
        filter((token: string) => !!token),
        switchMap(() => this.store.select(AuthState.isAdmin)),
        filter((isAdmin) => isAdmin),
        switchMap(() => ctx.dispatch(new LoadReportBackItems()))
      )
      .subscribe();
  }

  @Action(OpenReportBackItemsList)
  public openReportBackItemsList(
    ctx: StateContext<ReportBackItemsStateModel>
  ): void {
    const prefix = this.store.selectSnapshot(EnvironmentState.prefix);
    ctx.dispatch(new Navigate([`/${prefix}/report-back`]));
  }

  @Action(OpenReportBackItemDetails)
  public openReportBackItemDetails(
    ctx: StateContext<ReportBackItemsStateModel>,
    { id }: OpenReportBackItemDetails
  ): void {
    const prefix = this.store.selectSnapshot(EnvironmentState.prefix);
    ctx.dispatch(new Navigate([`/${prefix}/report-back/${id}`]));
  }

  @Action(LoadReportBackItems)
  public loadReportBackItems(
    ctx: StateContext<ReportBackItemsStateModel>
  ): Observable<Array<WorkOrder>> {
    return this.reportBackItemsService
      .getReportBackItems()
      .pipe(
        tap((reportBackItems: WorkOrder[]) =>
          ctx.patchState({ reportBackItems })
        )
      );
  }

  @Action(LoadReportBackItemDetails)
  public loadReportBackItemDetails(
    ctx: StateContext<ReportBackItemsStateModel>,
    { id }: LoadReportBackItemDetails
  ): Observable<WorkOrder> {
    return this.reportBackItemsService
      .getReportBackItemById(id)
      .pipe(
        tap((reportBackItem: WorkOrder) => ctx.patchState({ reportBackItem }))
      );
  }

  @Action(ReportBack)
  public reportBack(
    ctx: StateContext<ReportBackItemsStateModel>,
    { workOrderId, replacements }: ReportBack
  ): Observable<any> {
    return this.appEventsService
      .save({
        event: 'reportBackItems.reportBack',
        payload: {
          workOrderId,
          replacements,
        },
        shouldWait: true,
      })
      .pipe(
        tap(() => {
          return ctx.setState(
            patch({
              reportBackItems: removeItem<WorkOrder>(
                (i) => i.id === workOrderId
              ),
            })
          );
        }),
        switchMap(() => {
          const prefix = this.store.selectSnapshot(EnvironmentState.prefix);
          return ctx.dispatch(new Navigate([`${prefix}/report-back`]));
        })
      );
  }

  @Action(CancelReportBack)
  public cancelReportBack(
    ctx: StateContext<ReportBackItemsStateModel>,
    { workOrderId }: CancelReportBack
  ): Observable<any> {
    return this.appEventsService
      .save({
        event: 'reportBackItems.cancelReportBack',
        payload: {
          workOrderId,
        },
        shouldWait: true,
      })
      .pipe(
        tap(() => {
          return ctx.setState(
            patch({
              reportBackItems: removeItem<WorkOrder>(
                (i) => i.id === workOrderId
              ),
            })
          );
        }),
        switchMap(() => {
          const prefix = this.store.selectSnapshot(EnvironmentState.prefix);
          return ctx.dispatch(new Navigate([`${prefix}/report-back`]));
        })
      );
  }
}
