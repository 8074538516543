import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { EnvironmentStateModel } from './environment.state-model';
import { SetPrefix } from './environment.actions';

@State<EnvironmentStateModel>({
  name: 'environment',
  defaults: {
    prefix: ''
  }
})
@Injectable()
export class EnvironmentState {
  @Selector()
  static prefix(state: EnvironmentStateModel): string {
    return state.prefix;
  }

  @Action(SetPrefix)
  public setPrefix(ctx: StateContext<EnvironmentStateModel>, { prefix }: SetPrefix): EnvironmentStateModel {
    return ctx.patchState({ prefix });
  }
}
