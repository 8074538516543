import { ClassTransformOptions, classToPlain, plainToClass, classToClass } from 'class-transformer';
import { ClassType } from 'class-transformer/ClassTransformer';

export class BaseModel {
  public static classToPlain<T>(obj: T, options?: ClassTransformOptions): object;
  public static classToPlain<T>(obj: T[], options?: ClassTransformOptions): object[];
  public static classToPlain<T>(obj: T | T[], options?: ClassTransformOptions): object | object[] {
    const defaultOptions = { groups: ['related'] };
    return classToPlain(obj, { ...defaultOptions, ...options });
  }


  public static plainToClass<T, V>(cls: ClassType<T>, plain: V[], options?: ClassTransformOptions): T[];
  public static plainToClass<T, V>(cls: ClassType<T>, plain: V, options?: ClassTransformOptions): T;
  public static plainToClass<T, V>(cls: ClassType<T>, plain: V | V[], options?: ClassTransformOptions): T | T[] {
    const defaultOptions = { excludeExtraneousValues: true, groups: ['related'] };
    return plainToClass(cls, plain as any, { ...defaultOptions, ...options });
  }

  public static classToClass<T>(obj: T, options?: ClassTransformOptions): T;
  public static classToClass<T>(obj: T[], options?: ClassTransformOptions): T[];
  public static classToClass<T>(obj: T|T[], options?: ClassTransformOptions): T|T[] {
    const defaultOptions = { excludeExtraneousValues: true, groups: ['related'] };
    return classToClass(obj, { ... defaultOptions, ... options });

  }
}
