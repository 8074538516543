import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { IUser } from '@vandelft/shared/interfaces';

@Component({
  selector: 'app-planning-warning',
  templateUrl: './planning-warning.component.html',
})
export class PlanningWarningComponent implements OnChanges {
  @Input()
  public user: IUser;

  public showWarning = false;

  public showWarningTimes = [
    {
      days: {
        1: 'Maandag',
        2: 'Dinsdag',
        3: 'Woensdag',
        4: 'Donderdag',
        5: 'Vrijdag',
      },
      groupLabel: 'werkdagen',
      start: '08:00',
      end: '23:00'
    }, {
      days: {
        6: 'Zaterdag'
      },
      groupLabel: 'Zaterdag',
      start: '08:00',
      end: '18:00'
    }
  ];

  public get warningTimes(): Array<string> {
    const times = [];

    for (const time of this.showWarningTimes) {
      const timeText = `${time.groupLabel} (${time.start} - ${time.end})`;
      times.push(timeText);
    }


    return times;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('user' in changes) {
      const now = moment();
      const day = now.isoWeekday();

      for (const time of this.showWarningTimes) {
        if (!Object.keys(time.days).includes(`${day}`)) {
          continue;
        }

        const start = moment(time.start, 'hh:mm');
        const end = moment(time.end, 'hh:mm');
        this.showWarning = !(now.isBetween(start, end));
        return;
      }

      this.showWarning = false;
    }
  }
}
