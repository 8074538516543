import { Expose, Exclude } from 'class-transformer';

import { BaseModel } from './base-model';
import { IImage } from '@vandelft/shared/interfaces';

@Exclude()
export class Image extends BaseModel implements IImage {
  @Expose()
  public id: string;

  @Expose()
  public model: string;

  @Expose()
  public foreignKey: string;

  @Expose()
  public fileType: string;

  @Expose()
  public location: string;
}
