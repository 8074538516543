import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { WorkOrder } from '../models';

import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReportBackItemsService {
  public constructor(private http: HttpClient) {}

  public getReportBackItems(): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/report-back-items`)
      .pipe(
        map((data: WorkOrder[]) => WorkOrder.plainToClass(WorkOrder, data))
      );
  }

  public getReportBackItemById(id: string): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/report-back-items/${id}`)
      .pipe(
        map((data: WorkOrder) => WorkOrder.plainToClass(WorkOrder, data))
      );
  }
}
