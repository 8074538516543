import { v4 as uuid } from 'uuid';

import { Report, User, WorkOrder } from '../models';
import { ClassTransformOptions } from 'class-transformer';

export class ObjectGenerator {
  public generateNewReport(options: { user: User }, classTransformOptions?: ClassTransformOptions): Report {
    options = { ... options };
    return Report.plainToClass(Report, {
      id: uuid(),
      mechanic: options.user.role === 'mechanic' ? options.user : null,
      company: null,
      client: {
        id: uuid(),
        type: 'private'
      },
    }, classTransformOptions);
  }

  public generateNewWorkOrder(options: { user: User, report: Report }, classTransformOptions?: ClassTransformOptions): WorkOrder {
    const report = options.report ?? this.generateNewReport({ user: options.user });

    const workOrderData = {
      id: uuid(),
      state: 'draft',
      isNew: options.report ? false : true,
      mechanic: options.user.role === 'mechanic' ? options.user : null,
      report: Report.classToPlain(report) as Report
    };

    return WorkOrder.plainToClass(WorkOrder, workOrderData, classTransformOptions);
  }

  public patchWorkOrder(workOrder: WorkOrder, data: object, classTransformOptions?: ClassTransformOptions): WorkOrder {
    const workOrderData = WorkOrder.classToPlain(workOrder, classTransformOptions);

    const patch = (currentData: any, newData: any) => {
      for (const i of Object.keys(newData)) {
        const newValue = newData[i];

        if (Array.isArray(newValue)) {
          currentData[i] = patch([], newValue) || {};
          continue;
        }

        if (newValue instanceof Object && !(newValue instanceof Date)) {
          currentData[i] = patch(currentData[i] || {}, newValue) || {};
          continue;
        }

        currentData[i] = newValue;
      }

      return currentData;
    };

    const patchedData = patch(workOrderData, data);
    const patchedWorkOrder = WorkOrder.plainToClass(WorkOrder, patchedData, classTransformOptions);
    patchedWorkOrder.id = workOrder.id;
    return patchedWorkOrder;
  }
}
