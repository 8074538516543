import { Component } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { ConfirmModalComponent } from '@vandelft/modules/shared/components/ui/confirm-modal';
import { ModalService } from '@vandelft/modules/shared/services/modal.service';

import { User } from '@vandelft/modules/shared/models';
import {
  OpenUserForm,
  DeleteUser,
  UsersState,
} from '@vandelft/modules/shared/state/users';

@Component({
  selector: 'tna-users-list',
  templateUrl: './list.component.html',
})
export class ListComponent {
  @Select(UsersState.users)
  public users$: Observable<User[]>;

  public tabs = [
    {
      key: 'order',
      label: 'Opdracht',
    },
    {
      key: 'activities',
      label: 'Werkzaamheden',
    },
    {
      key: 'completion',
      label: 'Afronding',
    },
  ];

  public constructor(
    private store: Store,
    private modalService: ModalService
  ) {}

  public addItem(): void {
    this.store.dispatch([new OpenUserForm()]);
  }

  public selectItem(user: User): void {
    this.store.dispatch([new OpenUserForm(user)]);
  }

  public async deleteUser(e: Event, user: User): Promise<void> {
    e.preventDefault();
    e.stopPropagation();

    const modalRef = this.modalService.open(ConfirmModalComponent, {
      modalType: 'modal-floating-confirmation',
      barrier: false,
      alignment: {
        element: e.target,
      },
    });

    modalRef.componentInstance.message = `Weet u zeker dat ${user.fullName} wilt verwijderen?`;
    const result = await modalRef.result;

    if (!result) {
      return;
    }

    this.store.dispatch([new DeleteUser(user)]);
  }
}
