import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { LocalStoreService } from '@vandelft/modules/shared/services/local-store.service';
import { AppEventsService } from '@vandelft/modules/shared/services/app-events.service';

import { ProcessUploadQueue, ProcessPendingEvents } from './sync.actions';
import { SyncStateModel } from './sync.state-model';

import { classToPlain } from 'class-transformer';
import { RefreshUserDepositItem } from '../deposit';
import { interval, Subject } from 'rxjs';
import {
  switchMap,
  throttleTime,
  tap,
  filter,
} from 'rxjs/operators';
import { AuthState } from '../auth';
import { captureExceptionInSentry } from 'src/sentry-capturer';
import { v4 } from 'uuid';

@State<SyncStateModel>({
  name: 'sync',
  defaults: {
    isRunning: false,
  },
})
@Injectable()
export class SyncState {
  private processPendingEvents$ = new Subject();

  public constructor(
    private store: Store,
    private localStore: LocalStoreService,
    private appEventsService: AppEventsService
  ) {
    this.processPendingEvents$
      .pipe(
        throttleTime(60000),
        filter(() => this.store.selectSnapshot(AuthState.isAuthenticated)),
        switchMap(() => this.appEventsService.processingPending())
      )
      .subscribe();

    interval(1000)
      .pipe(tap(() => this.processPendingEvents$.next()))
      .subscribe();
  }

  @Selector()
  static isRunning(state: SyncStateModel): boolean {
    return state.isRunning;
  }

  @Action(ProcessPendingEvents)
  public async processPendingEvents(): Promise<void> {
    this.processPendingEvents$.next();
  }

  @Action(ProcessUploadQueue)
  public async processUploadQueue(
    ctx: StateContext<SyncStateModel>
  ): Promise<void> {
    const isRunning = this.store.selectSnapshot(SyncState.isRunning);

    if (isRunning) {
      return;
    }

    ctx.patchState({ isRunning: true });
    const workOrders = await this.localStore.getWorkOrders();

    let data = null;
    for (const workOrder of workOrders) {
      try {
        data = classToPlain(workOrder);

        await this.appEventsService
          .save({
            event: 'clientSignature.saved',
            payload: {
              id: v4(),
              workOrderId: data.id,
              data: data.localClientSignature,
            },
          })
          .toPromise();
        await this.appEventsService
          .save({
            event: 'mechanicSignature.saved',
            payload: {
              id: v4(),
              workOrderId: data.id,
              data: data.localMechanicSignature,
            },
          })
          .toPromise();

        for (const image of workOrder.localImages) {
          await this.appEventsService
            .save({
              event: 'workOrderImage.saved',
              payload: { workOrderId: data.id, ...image },
            })
            .toPromise();
        }

        for (const image of workOrder.order?.localCertificates ?? []) {
          await this.appEventsService
            .save({
              event: 'certificate.saved',
              payload: {
                workOrderId: data.id,
                orderId: data?.order?.id,
                ...image,
              },
            })
            .toPromise();
        }

        for (const image of workOrder.order?.localProductImages ?? []) {
          await this.appEventsService
            .save({
              event: 'productImage.saved',
              payload: {
                workOrderId: data.id,
                orderId: data?.order?.id,
                ...image,
              },
            })
            .toPromise();
        }

        delete data.workOrderImage;
        delete data.certificate;
        delete data.productImage;
        delete data.localImages;
        delete data.localMechanicSignature;
        delete data.localClientSignature;
        delete data.order?.localCertificates;
        delete data.order?.localProductImages;
        delete data.report.workOrders;
        delete data.isNew;

        await this.appEventsService
          .save({ event: 'workOrder.saved', payload: data })
          .toPromise();

        await this.localStore.deleteWorkOrder(workOrder);
      } catch (e) {
        captureExceptionInSentry(e);
      }
    }

    ctx.patchState({ isRunning: false });
    this.store.dispatch(new RefreshUserDepositItem());
  }
}
