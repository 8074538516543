import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { InvoicesStateModel } from './invoices.state-model';
import { InvoicesService } from '../../services/invoices.service';

import { LoadInvoices, OpenInvoicesList, OpenInvoicesDetails, LoadInvoice } from './invoices.actions';
import { EnvironmentState } from '../environment';
import { WorkOrder } from '../../models';

@State<InvoicesStateModel>({
  name: 'invoices',
  defaults: {
    workOrders: [],
    workOrder: null
  }
})
@Injectable()
export class InvoicesState {
  public constructor(
    private store: Store,
    private invoicesService: InvoicesService,
  ) { }

  @Selector()
  static workOrders(state: InvoicesStateModel): Array<WorkOrder> {
    return state.workOrders;
  }

  @Selector()
  static workOrder(state: InvoicesStateModel): WorkOrder {
    return state.workOrder;
  }

  @Action(OpenInvoicesList)
  public openInvoicesList(_: StateContext<InvoicesStateModel>): Observable<any> {
    const prefix = this.store.selectSnapshot(EnvironmentState.prefix);
    return this.store.dispatch( new Navigate([`/${prefix}/invoices`]) );
  }

  @Action(OpenInvoicesDetails)
  public  openInvoiceDetails(_: StateContext<InvoicesStateModel>, { workOrder }: OpenInvoicesDetails): Observable<any> {
    const prefix = this.store.selectSnapshot(EnvironmentState.prefix);

    if (workOrder) {
      return this.store.dispatch( new Navigate([`/${prefix}/invoices/${workOrder.id}`]) );
    }
  }

  @Action(LoadInvoices)
  public loadInvoices(ctx: StateContext<InvoicesStateModel>, { filters }: LoadInvoices): Observable<Array<WorkOrder>> {
    return this.invoicesService.getInvoices(filters).pipe(
      tap((workOrders: Array<WorkOrder>) => ctx.patchState({ workOrders }))
    );
  }

  @Action(LoadInvoice)
  public loadInvoice(ctx: StateContext<InvoicesStateModel>, { id }: LoadInvoice): Observable<WorkOrder> {
    return this.invoicesService.getInvoiceById(id).pipe(
      tap((workOrder: WorkOrder) => ctx.patchState({ workOrder }))
    );
  }
}
