import {Component, Input} from '@angular/core';
import {Report, User, WorkOrder} from '@vandelft/modules/shared/models';

@Component({
  selector: 'app-timeline-work-order',
  templateUrl: './timeline-work-order.component.html',
})
export class TimelineWorkOrderComponent {
  @Input()
  public workOrder: WorkOrder;

  @Input()
  public report: Report;

  @Input()
  public user: User;

  @Input()
  public apiUrl: string;

  @Input()
  public token: string;
}
