import { BaseModel } from './base-model';
import { Expose, Exclude, Type } from 'class-transformer';
import { IDepositItem } from '@vandelft/shared/interfaces';
import { User } from './user';
import { Payment } from './payment';

@Exclude()
export class DepositItem extends BaseModel implements IDepositItem {
  @Expose()
  @Type(() => User)
  public mechanic: User;

  @Expose()
  @Type(() => Payment)
  public payments: Payment[];

  public get totalAmount(): number {
    return this.payments.reduce((prev: number, cur: any) => {
      return prev + cur.amount;
    }, 0);
  }
}
