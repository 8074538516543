import { BaseModel } from './base-model';
import { Expose, Exclude, Transform } from 'class-transformer';
import { IPayment } from '@vandelft/shared/interfaces';
import {datetimeToPlain} from '../transformers';

@Exclude()
export class Payment extends BaseModel implements IPayment {
  @Expose()
  public id: string;

  @Expose()
  public workOrderId: string;

  @Expose()
  public amount: number;

  @Expose()
  @Transform(datetimeToPlain, { toPlainOnly: true })
  public date: Date;

  @Expose()
  public method: string;

  @Expose()
  public remarks: string;
}

