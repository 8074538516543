import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private socket: Socket;
  public constructor() {
    this.socket = io('https://vandelft-api.testing.thenextapp.dev', {
      reconnectionDelay: 1000,
      reconnectionDelayMax: 1000
    });

    this.socket.on('disconnect', () => {
      console.log('disconnect');
    });

    this.socket.on('reports', (data: any) => {
      console.log(data);
    });

    this.socket.on('connect', () => {
      console.log('connect');
    });
    this.socket.connect(); 
    this.socket.emit('reports', {});
  }

  public setToken(token: string) {
    this.socket.auth = {
      token: token
    }
  }
}
